import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { initCarousel } from "./carousel";
AOS.init();
AOS.refresh();

initCarousel();

document.getElementById("menu_button").addEventListener("click", function () {
    document.getElementById("mobile_menu").dataset.open = "true";
});

document
    .getElementById("menu_button_close")
    .addEventListener("click", function () {
        document.getElementById("mobile_menu").dataset.open = "false";
    });

document
    .querySelector("#mobile_menu .backdrop")
    .addEventListener("click", function () {
        document.getElementById("mobile_menu").dataset.open = "false";
    });

const navbarScrollTreshold = 200;
window.addEventListener(
    "scroll",
    function () {
        updateFixedNavbar();
    },
    { passive: true }
);

const updateFixedNavbar = function () {
    if (window.scrollY >= navbarScrollTreshold) {
        if (document.body.dataset.fixednavbar === "true") {
            return;
        }
        document.body.dataset.fixednavbar = "true";
        return;
    }

    document.body.dataset.fixednavbar = "false";
};
updateFixedNavbar();

const updateCurrentLocation = () => {
    const currentLocation = window.location.href;
    const links = document.querySelectorAll("a[href]");
    links.forEach((link) => {
        link.classList.remove("active");

        if (link.href === currentLocation) {
            link.classList.add("active");
        }
    });
};
updateCurrentLocation();
