let CAROUSELS = [];
export const initCarousel = () => {
    const containers = document.querySelectorAll(".carousel");
    containers.forEach((container) => {
        CAROUSELS.push(new Carousel(container));
    });
};

export const getCarousel = (id) => {
    return CAROUSELS.find((carousel) => carousel.id === id);
};

export class Carousel {
    constructor(container) {
        this.container = container;
        this.id = container.id || Math.random().toString(36).substr(2, 9);
        this.items = container.querySelectorAll(".carousel-item");
        this.index = 0;

        this.initDots();
        this.initAutoPlay();

        container.addEventListener("scroll", () => {
            const scrollPosition = container.scrollLeft;
            const index = Math.round(scrollPosition / container.offsetWidth);

            this.updateDots(index);
        });
    }

    initDots() {
        const dotsContainers = document.querySelectorAll(
            '.carousel-dots[data-carousel-id="' + this.id + '"]'
        );
        this.dots = [];

        dotsContainers.forEach((dotsContainer) => {
            this.items.forEach((item, index) => {
                const dot = document.createElement("div");
                dot.classList.add("carousel-dot");
                dot.addEventListener("click", () => {
                    this.changeSlide(index);
                });
                dot.dataset.index = index;
                dotsContainer.appendChild(dot);
                this.dots.push(dot);
            });
        });

        this.updateDots(this.index);
    }

    initAutoPlay() {
        this.autoPlay = this.container.dataset.autoplay;
        if (!this.autoPlay) return;

        this.autoPlayInterval = setInterval(() => {
            const scrollPosition = this.container.scrollLeft;
            const index = Math.round(
                scrollPosition / this.container.offsetWidth
            );
            const nextIndex = (index + 1) % this.items.length;
            this.changeSlide(nextIndex, true);
        }, this.autoPlay);
    }

    changeSlide(index, keepInterval = false) {
        const scrollPosition = index * this.container.offsetWidth;
        this.container.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
        });
        this.index = index;

        if (!keepInterval) {
            clearInterval(this.autoPlayInterval);
            this.initAutoPlay();
        }

        this.updateDots(index);
    }

    updateDots(index) {
        const dots = this.dots.filter((dot) => dot.dataset.index == index);

        this.dots.forEach((dot) => {
            dot.classList.remove("active");
        });

        dots.forEach((dot) => {
            dot.classList.add("active");
        });
    }
}
